import reactImage from "../assets/reactImage.jpg"
import redux from "../assets/redux.png"
export const upcoming = [
    {
        "title": "Getting started with web development",
        image: reactImage,
    },
    {
        title: "Learning advanced state management with Redux",
        image: redux
    }
]